import React from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { StepTracker } from '../components';
import withCheckout from '../hoc/withCheckout';
import CreditPayment from './CreditPayment';
import PayPalPayment from './PayPalPayment';
import Total from './Total';
import { displayActionMessage } from '@/helpers/utils';
import { CHECKOUT_STEP_1 } from '@/constants/routes';
import { useDocumentTitle, useScrollTop } from '@/hooks';
import { Redirect } from 'react-router-dom';

const FormSchema = Yup.object().shape({
  // Your Yup validation schema
});

const Payment = ({ shipping, payment, subtotal }) => {
  useDocumentTitle('Check Out Final Step | DreamTV');
  useScrollTop();

  const initFormikValues = {
    // Initial Formik values
  };

  const handleSubmit = async (values) => {
    try {
      // Handle submit
    } catch (error) {
      console.error('Payment processing error:', error);
      displayActionMessage('Payment failed. Please try again.', 'error');
    }
  };

  if (!shipping || !shipping.isDone) {
    return <Redirect to={CHECKOUT_STEP_1} />;
  }

  const totalAmount = subtotal + (shipping.isInternational ? 0 : 0); // Calculate total amount

  return (
    <div className="checkout">
      <StepTracker current={3} />
      <Formik
        initialValues={initFormikValues}
        validateOnChange
        validationSchema={FormSchema}
        onSubmit={handleSubmit}
      >
        {formikProps => (
          <Form className="checkout-step-3">
            <CreditPayment
              {...formikProps}
              totalAmount={totalAmount} // Pass totalAmount to CreditPayment
            />
            <PayPalPayment />
            <Total
              isInternational={shipping.isInternational}
              subtotal={subtotal}
              currentStep={3} // Pass current step
            />
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default withCheckout(Payment);