import {
  ADD_PRODUCT,
  ADD_PRODUCT_SUCCESS,
  CANCEL_GET_PRODUCTS,
  CLEAR_SEARCH_STATE,
  EDIT_PRODUCT,
  EDIT_PRODUCT_SUCCESS,
  GET_PRODUCTS,
  GET_PRODUCTS_SUCCESS,
  REMOVE_PRODUCT,
  REMOVE_PRODUCT_SUCCESS,
  SEARCH_PRODUCT,
  SEARCH_PRODUCT_SUCCESS
} from '@/constants/constants';

// Action to fetch products with an optional lastRef for pagination
export const getProducts = (lastRef) => ({
  type: GET_PRODUCTS,
  payload: lastRef
});

// Action dispatched when products are successfully fetched
export const getProductsSuccess = (products) => ({
  type: GET_PRODUCTS_SUCCESS,
  payload: products
});

// Action to cancel a fetch products request
export const cancelGetProducts = () => ({
  type: CANCEL_GET_PRODUCTS
});

// Action to add a new product
export const addProduct = (product) => ({
  type: ADD_PRODUCT,
  payload: product
});

// Action dispatched when a product is successfully added
export const addProductSuccess = (product) => ({
  type: ADD_PRODUCT_SUCCESS,
  payload: product
});

// Action to remove a product by its ID
export const removeProduct = (id) => ({
  type: REMOVE_PRODUCT,
  payload: id
});

// Action dispatched when a product is successfully removed
export const removeProductSuccess = (id) => ({
  type: REMOVE_PRODUCT_SUCCESS,
  payload: id
});

// Action to edit an existing product
export const editProduct = (id, updates) => ({
  type: EDIT_PRODUCT,
  payload: { id, updates }
});

// Action dispatched when a product is successfully edited
export const editProductSuccess = (updates) => ({
  type: EDIT_PRODUCT_SUCCESS,
  payload: updates
});

// Action to search for products with a search key
export const searchProduct = (searchKey) => ({
  type: SEARCH_PRODUCT,
  payload: { searchKey }
});

// Action dispatched when search results are successfully fetched
export const searchProductSuccess = (products) => ({
  type: SEARCH_PRODUCT_SUCCESS,
  payload: products
});

// Action to clear the search state
export const clearSearchState = () => ({
  type: CLEAR_SEARCH_STATE
});