import { LoadingOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

const ImageLoader = ({ src, alt, className }) => {
  // Use state to track image loading status
  const [loaded, setLoaded] = useState(false);

  // Handler for when the image has loaded
  const onLoad = () => {
    setLoaded(true);
  };

  // Handler for when the image fails to load
  const onError = () => {
    // Set a default image on error
    setLoaded(true); // Ensure loader disappears
  };

  return (
    <>
      {!loaded && (
        <LoadingOutlined
          style={{
            position: 'absolute',
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            margin: 'auto',
          }}
        />
      )}
      <img
        alt={alt || ''}
        className={`${className || ''} ${loaded ? 'is-img-loaded' : 'is-img-loading'}`}
        onLoad={onLoad}
        onError={onError} // Handle image load error
        src={src}
      />
    </>
  );
};

ImageLoader.defaultProps = {
  className: 'image-loader',
  alt: '',
};

ImageLoader.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string,
  className: PropTypes.string,
};

export default ImageLoader;