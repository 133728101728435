/* eslint-disable react/forbid-prop-types */
import { Preloader } from '@/components/common';
import PropType from 'prop-types';
import React, { StrictMode } from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import AppRouter from '@/routers/AppRouter';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';

const App = ({ store, persistor }) => (
  <StrictMode>
    <Provider store={store}>
      <PersistGate loading={<Preloader />} persistor={persistor}>
        <PayPalScriptProvider options={{ 
          'client-id': 'AR8CG_xDNiZk-C9HC0-iqA_5KsgxnXLCc4t7PJXJtPCwJ5OEdTB25uWlCs8xCuEnPoDbAVyunAkIn2bx', // Replace with your PayPal client ID
          currency: 'CAD' // Replace with your preferred currency
        }}>
          <AppRouter />
        </PayPalScriptProvider>
      </PersistGate>
    </Provider>
  </StrictMode>
);

App.propTypes = {
  store: PropType.any.isRequired,
  persistor: PropType.any.isRequired
};

export default App;