import React, { useEffect, useRef } from 'react';
import { Field } from 'formik';
import { PayPalButtons } from '@paypal/react-paypal-js';
import PropTypes from 'prop-types';

const CreditPayment = ({ values, setValues, totalAmount }) => {
  const collapseContainerRef = useRef(null);
  const containerRef = useRef(null);
  const checkboxContainerRef = useRef(null);

  const toggleCollapse = () => {
    const cn = containerRef.current;
    const cb = checkboxContainerRef.current;
    const cl = collapseContainerRef.current;

    if (cb && cn && cl) {
      if (values.type === 'credit') {
        cn.style.height = `${cb.offsetHeight + cl.offsetHeight}px`;
      } else {
        cn.style.height = `${cb.offsetHeight}px`;
      }
    }
  };

  useEffect(() => {
    toggleCollapse();
  }, [values.type]);

  const onCreditModeChange = (e) => {
    if (e.target.checked) {
      setValues({ ...values, type: 'credit' });
      toggleCollapse();
    }
  };

  const handleOnlyNumberInput = (e) => {
    const { key } = e.nativeEvent;
    if (/\D/.test(key) && key !== 'Backspace') {
      e.preventDefault();
    }
  };

  const handlePaypalPayment = (orderID) => {
    // Handle PayPal payment success
    console.log('PayPal payment completed. Order ID:', orderID);
    // Add further actions here
  };

  return (
    <>
      <h3 className="text-center">Payment</h3>
      <br />
      <span className="d-block padding-s">Payment Option</span>
      <div
        ref={containerRef}
        className={`checkout-fieldset-collapse ${
          values.type === 'credit' ? 'is-selected-payment' : ''
        }`}
      >
        <div className="checkout-field margin-0">
          <div className="checkout-checkbox-field" ref={checkboxContainerRef}>
            <input
              checked={values.type === 'credit'}
              id="modeCredit"
              name="type"
              value="credit"
              onChange={onCreditModeChange}
              type="radio"
            />
            <label className="d-flex w-100" htmlFor="modeCredit">
              <div className="d-flex-grow-1 margin-left-s">
                <h4 className="margin-0">PayPal or Debit/Credit Card</h4>
                <span className="text-subtle d-block margin-top-s">
                  Pay with Visa, Master Card Debit Card or PayPal
                </span>
              </div>
              <div className="d-flex">
                <div className="payment-img payment-img-visa" />
                &nbsp;
                <div className="payment-img payment-img-mastercard" />
              </div>
            </label>
          </div>
        </div>
        <div className="checkout-collapse-sub" ref={collapseContainerRef}>
          <span className="d-block padding-s text-center">Accepted Cards</span>
          
          <div className="checkout-cards-accepted d-flex-center">
            <div className="payment-img payment-img-visa" title="Visa" />
            <div className="payment-img payment-img-express" title="American Express" />
            <div className="payment-img payment-img-mastercard" title="Master Card" />
            <div className="payment-img payment-img-maestro" title="Maestro" />
            <div className="payment-img payment-img-discover" title="Discover" />
          </div>
          
          <span className="d-block padding-s text-center">*Please use Email in which your DreamTV account is registered to*<br />Orders during business hours are normally processed within 15 minutes but please allow up to 2 hours for your DreamTV account to be updated.</span>
          <br />
          <div className="checkout-field margin-0">
            <div className="checkout-fieldset">
              {/* Removed Name on Card field */}
            </div>
            <div className="checkout-fieldset">
              {/* Add other fields as necessary */}
            </div>
            <PayPalButtons
              createOrder={(data, actions) => {
                // Use the totalAmount for the PayPal order
                return actions.order.create({
                  purchase_units: [
                    {
                      amount: {
                        value: totalAmount.toFixed(2), // Use the passed totalAmount here
                      },
                    },
                  ],
                });
              }}
              onApprove={(data, actions) => {
                return actions.order.capture().then(function (details) {
                  handlePaypalPayment(details.id);
                });
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

CreditPayment.propTypes = {
  values: PropTypes.object.isRequired,
  setValues: PropTypes.func.isRequired,
  totalAmount: PropTypes.number.isRequired, // Ensure totalAmount is required
};

export default CreditPayment;