import React from 'react';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import { displayMoney } from '@/helpers/utils';

const ShippingTotal = ({ subtotal }) => {
  const { values } = useFormikContext();

  // Function to calculate shipping cost based on selected option
  const calculateShippingCost = () => {
    switch (values.shippingOption) {
      case 'International Shipping':
        return values.isInternational ? 50.00 : 0.00; // International shipping costs $50.00
      case 'Local Pickup':
        return 0.00; // Local pickup is free
      case 'Canada-Wide':
        return 10.00; // Canada-Wide shipping costs $10.00
      case 'No Shipping Required':
        return 0.00; // No shipping required
      default:
        return 0.00; // Default to 0.00 if no option is selected
    }
  };

  // Calculate shipping cost
  const shippingCost = calculateShippingCost();

  // Calculate total amount including subtotal and shipping cost
  const totalAmount = parseFloat(subtotal.toFixed(2)) + parseFloat(shippingCost.toFixed(2));

  return (
    <div className="checkout-total d-flex-end padding-right-m">
      <table>
        <tbody>
          <tr>
            <td>
              <span className="d-block margin-0 padding-right-s text-right">
                Shipping Cost: &nbsp;
              </span>
            </td>
            <td>
              <h4 className="basket-total-amount text-subtle text-right margin-0">
                {values.shippingOption !== 'No Shipping Required' ? displayMoney(shippingCost) : '$0.00'}
              </h4>
            </td>
          </tr>
          <tr>
            <td>
              <span className="d-block margin-0 padding-right-s text-right">
                Subtotal: &nbsp;
              </span>
            </td>
            <td>
              <h4 className="basket-total-amount text-subtle text-right margin-0">
                {displayMoney(subtotal)}
              </h4>
            </td>
          </tr>
          <tr>
            <td>
              <span className="d-block margin-0 padding-right-s text-right">
                Total: &nbsp;
              </span>
            </td>
            <td>
              <h2 className="basket-total-amount text-right">
                {displayMoney(totalAmount)}
              </h2>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

ShippingTotal.propTypes = {
  subtotal: PropTypes.number.isRequired,
};

export default ShippingTotal;