import { ArrowRightOutlined, DownOutlined, UpOutlined } from '@ant-design/icons';
import { MessageDisplay } from '@/components/common';
import { ProductShowcaseGrid } from '@/components/product';
import { FEATURED_PRODUCTS, RECOMMENDED_PRODUCTS, SHOP } from '@/constants/routes';
import {
  useDocumentTitle, useFeaturedProducts, useRecommendedProducts, useScrollTop
} from '@/hooks';
import bannerImg from '@/images/banner-girl.png';
import React from 'react';
import { Link } from 'react-router-dom';

// Import Toggle Collapse Components
import Android from './android';
import Iphone from './iphone';
import SmartTV from './smarttv';

// Path to the Google Drive file
const googleDriveFileId = '1EA1fyO_XhAlztn_AdNdqyRwOvymGmtux';
const apkDownloadLink = `https://drive.google.com/uc?export=download&id=${googleDriveFileId}`;

const Setup = () => {
  useDocumentTitle('DreamTV | Setup');
  useScrollTop();

  return (
    <main className="content">
      <style>
        {`
          .star-list {
            list-style-type: none;
            padding-left: 20px;
          }

          .star-list li:before {
            content: "\\2605"; /* Hexadecimal escape sequence for yellow star */
            color: yellow;
            margin-right: 5px;
          }

          .toggle-collapse {
            margin-bottom: 10px;
          }

          .toggle-collapse-btn {
            background-color: #f0f0f0;
            border: none;
            padding: 10px;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
          }

          .collapse-content {
            transition: height 0.3s ease;
            overflow: hidden;
          }

          .download-btn {
            margin-top: 20px;
            padding: 10px 20px;
            background-color: #1890ff;
            color: #fff;
            border: none;
            border-radius: 5px;
            cursor: pointer;
            text-align: center;
            display: inline-block;
          }

          .download-btn:hover {
            background-color: #40a9ff;
          }
        `}
      </style>

      <div className="home">
        <div className="banner">
          <div className="banner-desc">
            <h1 className="text-thin">
              <strong>Setup</strong>
            </h1>
            <p>
              Set up your streaming service effortlessly with DreamTV. Enjoy a fast and easy setup process compatible with a variety of devices. Our expert support team is available around the clock to assist you every step of the way.
            </p>
            <br />
          </div>
          <div className="banner-img"><img src={bannerImg} alt="" /></div>
        </div>

        {/* Toggle Collapse Components */}
        <Android />
        <Iphone />
        <SmartTV />

        {/* Download Button */}
        <a href={apkDownloadLink} download="dreamtv.apk" className="download-btn">
          Download DreamTV APK
        </a>
      </div>
    </main>
  );
};

export default Setup;
