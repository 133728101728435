import { ArrowRightOutlined } from '@ant-design/icons';
import { MessageDisplay } from '@/components/common';
import { ProductShowcaseGrid } from '@/components/product';
import { FEATURED_PRODUCTS, RECOMMENDED_PRODUCTS, SHOP } from '@/constants/routes';
import { useDocumentTitle, useFeaturedProducts, useRecommendedProducts, useScrollTop } from '@/hooks';
import bannerImg from '@/images/banner-girl.png';
import React from 'react';
import { Link } from 'react-router-dom';

// Example image imports (replace with actual paths)
import img1 from '@/images/sports.png';
import img2 from '@/images/movies.png';
import img3 from '@/images/shows.png';
import img4 from '@/images/documentaries.png';
import img5 from '@/images/bestiptv.png';
import img6 from '@/images/playicon.png';
import img7 from '@/images/seamlessicon.png';
import img8 from '@/images/qualityicon.png';
import img9 from '@/images/server.png';
import img10 from '@/images/money.png';
import img11 from '@/images/support.png';

const Home = () => {
  useDocumentTitle('DreamTV | Home');
  useScrollTop();

  const {
    featuredProducts,
    fetchFeaturedProducts,
    isLoading: isLoadingFeatured,
    error: errorFeatured
  } = useFeaturedProducts(6);

  const {
    recommendedProducts,
    fetchRecommendedProducts,
    isLoading: isLoadingRecommended,
    error: errorRecommended
  } = useRecommendedProducts(6);

  return (
    <main className="content">
     <style>
  {`
    .star-list {
      list-style-type: none;
      padding-left: 20px;
    }

    .star-list li:before {
      content: "\\2605"; /* Hexadecimal escape sequence for yellow star */
      color: yellow;
      margin-right: 5px;
    }

    .image-container-wrapper {
      display: flex;
      flex-direction: column; /* Stack images vertically */
      justify-content: center; /* Center images vertically */
      align-items: center; /* Center images horizontally */
      min-height: 50vh; /* Minimum height for vertical centering */
      margin-bottom: 20px;
    }

    .image-container {
      display: flex;
      flex-direction: column; /* Stack images vertically */
      align-items: center; /* Center images horizontally */
      gap: 10px; /* Space between images */
    }

    .image-container img {
      width: 80%; /* Adjust size as needed */
      height: auto;
      border-radius: 8px;
    }

    .steps-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: center; /* Center horizontally */
      align-items: flex-start; /* Align items to the start vertically */
      gap: 20px;
      margin: 20px 0;
    }

    .step-box {
      flex: 1 1 300px; /* Adjust the flex-basis for better responsiveness */
      max-width: 300px; /* Set a max width */
      background-color: #f8f9fa; /* Light grey background */
      padding: 20px;
      border-radius: 8px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      text-align: center;
    }

    .step-box h2 {
      font-size: 1.5em;
      margin-bottom: 10px;
    }

    .step-box p {
      font-size: 1em;
      color: #555;
    }

    .feature-steps-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: center; /* Center horizontally */
      align-items: flex-start; /* Align items to the start vertically */
      gap: 20px;
      margin: 20px 0;
    }

    .feature-step {
      flex: 1 1 250px; /* Adjust the flex basis for better responsiveness */
      max-width: 300px; /* Set a max width */
      background: white;
      border: 1px solid #e0e0e0;
      border-radius: 10px;
      padding: 20px;
      text-align: center;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      transition: all 0.3s ease-in-out;
    }

    .feature-step img {
      width: 50px;
      height: 50px;
      margin-bottom: 15px;
    }

    .feature-step:hover {
      background: #e0f7fa;
      box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
    }

    @media (max-width: 768px) {
      .feature-steps-container {
        flex-direction: column; /* Stack vertically on smaller screens */
        align-items: center; /* Center items horizontally in column layout */
      }

      .feature-step {
        flex: 1 1 auto; /* Allow step boxes to take up available space */
        max-width: 100%; /* Ensure they don't exceed screen width */
      }

      .steps-container {
        flex-direction: column;
        align-items: center;
      }
    }
  `}
</style>

      <div className="home">
        <div className="banner">
          <div className="banner-desc">
            <h1 className="text-thin">
              &nbsp;Dream&nbsp;
              <strong>TV</strong>
            </h1>
            <p>
              DreamTV your ultimate destination for affordable, high-definition and 4K streaming. With over 25,000 channels and a vast library of 120,000 video-on-demand options, DreamTV offers unparalleled variety and quality in entertainment. Discover seamless streaming, crystal-clear visuals, and endless choices in movies, series, sports, PPV, and more—all at your fingertips.
            </p>
            <br />
          </div>
          <div className="banner-img"><img src={bannerImg} alt="DreamTV Banner" /></div>
        </div>

        <div className="banner-desc">
          <h1 className="text-thin">
            <strong>Features</strong>
          </h1>
          <p>
            Elevate your entertainment hassle-free with our premium IPTV service in Canada! Enjoy top-notch live TV channels and on-demand content in stunning HD and 4K quality, accessible on a variety of devices. Say goodbye to interruptions and experience the best effortlessly with just a simple internet connection!
          </p>
          <br />
          <ul className="star-list">
            <li>Quality SD/HD/FHD/4K</li><br />
            <li>+25,000 Live Channels</li><br />
            <li>+120,000 VOD & Series</li><br />
            <li>Exclusive Pay-Per-View Events</li><br />
            <li>Catch-up / EPG Integration</li><br />
            <li>24/7 Expert Assistance</li><br />
            <li>Compatible with All Devices</li><br />
            <li>Fast & Easy Payments</li><br />
          </ul>
          <br />
          <div className="image-container-wrapper">
            <div className="image-container">
              <img src={img1} alt="Feature 1" />
              <img src={img2} alt="Feature 2" />
              <img src={img3} alt="Feature 3" />
              <img src={img4} alt="Feature 4" />
            </div>
            <div className="banner-desc">
            <div className="feature-steps-container">
            <div className="feature-step">
              <img src={img6} alt="Play Anywhere" />
              <h3>Play Anywhere, Anytime</h3>
              <p>Enjoy your favorite entertainment on any device with our versatile IPTV service.</p>
            </div>
            <div className="feature-step">
              <img src={img7} alt="Seamless Streaming" />
              <h3>Seamless Streaming, No Buffering!</h3>
              <p>Tired of interruptions during your favorite shows? Say goodbye to buffering headaches! Our state-of-the-art servers ensure a smooth experience every time.</p>
            </div>
            <div className="feature-step">
              <img src={img8} alt="High Quality Video" />
              <h3>High Quality Video</h3>
              <p>Access TV channels in stunning HD, Ultra HD, 4K, and SD for the best viewing experience.</p>
            </div>
            <div className="feature-step">
              <img src={img9} alt="Server Uptime" />
              <h3>99.95% Server Uptime</h3>
              <p>Count on us for a consistent, uninterrupted streaming service with an impressive 99.95% server uptime.</p>
            </div>
            <div className="feature-step">
              <img src={img10} alt="Money-Back Guarantee" />
              <h3>7-Day Money-Back Guarantee</h3>
              <p>Your happiness is our main concern! That's why we provide a 7-day money-back guarantee.</p>
            </div>
            <div className="feature-step">
              <img src={img11} alt="Support" />
              <h3>24/7 Support</h3>
              <p>Need help? Our customer support is ready 24/7 for fast and responsive assistance. Your satisfaction is our priority!</p>
            </div>
          </div>
          <h1 className="text-thin">
            <strong>Doubting the Best IPTV in Canada? Think Again!</strong>
          </h1>
          <p>
          Still on the fence about choosing DreamTV? We get it.<br /> Our unbelievable prices and unbeatable features might seem too good to be true. But here's the deal, we're not just talk.<br /> As pioneers in IPTV, we don't just make promises; we deliver!<br /> To prove it, we're offering you something extraordinary: 100% Satisfaction or Your Money Back!<br /> Yes, you heard it right! We're that confident. No risks, only rewards.<br /> Ready to elevate your entertainment game? Don't wait, subscribe now and unlock the door to ultimate satisfaction!<br />
          </p>
          <div className="image-container-wrapper">
            <div className="image-container">
              <img src={img5} alt="Feature 1" />
            </div>
            </div>  
          </div>
          </div> 
          </div>
<div >
          
          <div className="feature-steps-container">
            <div className="feature-step">
              <h3>Step 1: Select Your Subscription</h3>
              <p>Get started by picking the plan that suits you best. We offer multiple options: 1 month, 3 months, 6 months, and 1 year. Your entertainment, your choice!</p>
            </div>
            <div className="feature-step">
              <h3>Step 2: Confirm Your Order</h3>
              <p>After choosing your ideal plan, simply make your payment to secure your subscription. We highly recommend the 12 Months Package – a top choice among our customers, saving you $20. Don't miss out on the best value!</p>
            </div>
            <div className="feature-step">
              <h3>Step 3: Payment Confirmed!</h3>
              <p>Get your Xstream DreamTV login details in an email and updated in your DreamTV account, let the entertainment begin. </p>
            </div>
            <div className="feature-step">
              <h3>Step 4: Choose IPTV App</h3>
              <p>Visit your devices App Store, search and download IPTV App that supports Xstream codes. Visit our Setup page for App Links!</p>
            </div>
            <div className="feature-step">
              <h3>Step 5: Choose IPTV App</h3>
              <p>Start the IPTV App and enter your DreamTV Username, Password and URL. Time to grab some popcorn and enjoy your favorite shows at your convenience!</p>
            </div>
            </div>
          </div>
          <div >
          <Link to={SHOP} className="button">
            Subscribe &nbsp;
            <ArrowRightOutlined />
          </Link>
        </div>

        <div className="display">
          <div className="display-header">
            <h1>Featured Products</h1>
            <Link to={FEATURED_PRODUCTS}>See All</Link>
          </div>
          {(errorFeatured && !isLoadingFeatured) ? (
            <MessageDisplay
              message={errorFeatured}
              action={fetchFeaturedProducts}
              buttonLabel="Try Again"
            />
          ) : (
            <ProductShowcaseGrid
              products={featuredProducts}
              skeletonCount={6}
            />
          )}
        </div>

        <div className="display">
          <div className="display-header">
            <h1>Recommended Products</h1>
            <Link to={RECOMMENDED_PRODUCTS}>See All</Link>
          </div>
          {(errorRecommended && !isLoadingRecommended) ? (
            <MessageDisplay
              message={errorRecommended}
              action={fetchRecommendedProducts}
              buttonLabel="Try Again"
            />
          ) : (
            <ProductShowcaseGrid
              products={recommendedProducts}
              skeletonCount={6}
            />
          )}
        </div>
      </div>
    </main>
  );
};

export default Home;