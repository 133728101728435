import React from 'react';
import { ArrowLeftOutlined, CheckOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { setPaymentDetails } from '@/redux/actions/checkoutActions';
import { clearBasket } from '@/redux/actions/basketActions';
import { useFormikContext } from 'formik';
import { CHECKOUT_STEP_2 } from '@/constants/routes';
import { displayActionMessage } from '@/helpers/utils'; // Ensure displayActionMessage is properly imported

const Total = ({ isInternational, subtotal }) => {
  const { values, submitForm } = useFormikContext();
  const history = useHistory();
  const dispatch = useDispatch();

  const onClickBack = () => {
    const { cardnumber, ccv, ...rest } = values;
    dispatch(setPaymentDetails({ ...rest }));
    history.push(CHECKOUT_STEP_2);
  };

  const handleConfirm = async () => {
    try {
      await submitForm(); // Submit form (assuming it triggers payment process)

      // Assuming payment was successful:
      displayActionMessage('Payment Successful!', 'success');

      // Clear the basket and navigate to the home page
      dispatch(clearBasket()); // Clear basket using Redux action
      history.push('/'); // Navigate to home page after clearing basket
    } catch (error) {
      console.error('Error submitting form:', error);
      displayActionMessage('Payment failed. Please try again.', 'error');
      // Handle error if needed
    }
  };

  // Calculate shipping cost based on shipping option
  const getShippingCost = () => {
    switch (values.shippingOption) {
      case 'International Shipping':
        return 50.00;
      case 'Local Pick-up':
        return 0.00;
      case 'Canada-Wide':
        return 10.00;
      case 'Express Shipping':
        return 20.00;
      default:
        return 0.00;
    }
  };

  // Calculate total amount including subtotal and shipping cost
  const shippingCost = getShippingCost();
  const totalAmount = subtotal + shippingCost;

  // Function to format money for display
  const formatMoney = (amount) => {
    return Number(amount).toFixed(2);
  };

  return (
    <>
      <div className="basket-total text-right">
        <p className="basket-total-title">Total:</p>
        <h2 className="basket-total-amount">
          ${formatMoney(totalAmount)}
        </h2>
      </div>
      <br />
      <div className="checkout-shipping-action">
        <button
          className="button button-muted"
          onClick={onClickBack}
          type="button"
        >
          <ArrowLeftOutlined />
          &nbsp;
          Go Back
        </button>
        <button
          className="button"
          onClick={handleConfirm}
          type="button"
        >
          Finished
          &nbsp;
          <CheckOutlined />
        </button>
      </div>
    </>
  );
};

Total.propTypes = {
  isInternational: PropTypes.bool.isRequired,
  subtotal: PropTypes.number.isRequired,
};

export default Total;