import React, { useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useFormikContext } from 'formik';
import { PayPalButtons } from '@paypal/react-paypal-js';
import { displayActionMessage } from '@/helpers/utils';
import { CheckOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { clearBasket } from '@/redux/actions/basketActions';

const PayPalPayment = () => {
  const { values, setValues, submitForm } = useFormikContext();
  const history = useHistory();
  const dispatch = useDispatch();

  const collapseContainerRef = useRef(null);
  const containerRef = useRef(null);
  const checkboxContainerRef = useRef(null);

  const toggleCollapse = () => {
    const cn = containerRef.current;
    const cb = checkboxContainerRef.current;
    const cl = collapseContainerRef.current;

    if (cb && cn && cl) {
      if (values.type === 'paypal') {
        cn.style.height = `${cb.offsetHeight + cl.offsetHeight}px`;
      } else {
        cn.style.height = `${cb.offsetHeight}px`;
      }
    }
  };

  useEffect(() => {
    toggleCollapse();
  }, [values.type]);

  const onPaymentModeChange = (e) => {
    if (e.target.checked) {
      setValues({ ...values, type: 'paypal' });
      toggleCollapse();
    }
  };

  const handlePaypalPayment = (orderID) => {
    console.log('PayPal payment completed. Order ID:', orderID);
    displayActionMessage('Payment Successful!', 'success');
    emptyBasketAndNavigate(); // Empty basket and navigate to home page after payment
  };

  const handlePaypalError = (err) => {
    console.error('PayPal error:', err);
    displayActionMessage('Payment failed. Please try again.', 'error');
  };

  const emptyBasketAndNavigate = () => {
    dispatch(clearBasket()); // Clear basket using Redux action
    history.push('/'); // Navigate to home page after clearing basket
  };

  return (
    <>
      <div
        ref={containerRef}
        className={`checkout-fieldset-collapse ${
          values.type === 'paypal' ? 'is-selected-payment' : ''
        }`}
      >
        <div className="checkout-field margin-0">
          <div className="checkout-checkbox-field" ref={checkboxContainerRef}>
            <input
              checked={values.type === 'paypal'}
              id="modePayPal"
              name="type"
              value="paypal"
              onChange={onPaymentModeChange}
              type="radio"
            />
            <label
              className="d-flex w-100"
              htmlFor="modePayPal"
            >
              <div className="d-flex-grow-1 margin-left-s">
                <h4 className="margin-0">Interac Etransfer</h4>
                <span className="text-subtle d-block margin-top-s">
                  Pay easily, fast and secure with Interac Etransfer.
                </span>
              </div>
              <div className="payment-img payment-img-paypal" />
            </label>
          </div>
        </div>
        
        <div
          className="checkout-collapse-sub"
          ref={collapseContainerRef}
          style={{ overflow: 'visible', transition: 'height 0.3s ease', backgroundColor: 'white', color: 'black' }}
        >
          {values.type === 'paypal' && (
            <div className="paypal-buttons-container">
              <p style={{ color: 'black' }}>Welcome User,</p>
              <p>To proceed with your purchase please send payment total to:</p>
              <p><u><i>TVDreamIPTV@outlook.com</i></u></p>
              <p>Make sure to include your DreamTV Account registered email address as the Memo</p>
              <p>If a password is required use <i><u>Toronto</u></i> for the password</p>
              <p>Although orders are normally processed within 15 minutes please allow up to 2 hours for your DreamTV Account to be updated!<h5>*This applies to orders during business hours*</h5></p>
              
              
            </div>
          )}
        </div>
      </div>
    </>
  );
};

PayPalPayment.propTypes = {
  // No need to define propTypes for values and setValues here
};

export default PayPalPayment;