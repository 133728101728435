import React, { useState } from 'react';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import smartTvImage from './images/androidiptv1.jpg'; // Import your image
import smartTvImage2 from './images/androidiptv2.jpg'; // Import your image
import smartTvImage3 from './images/androidiptv3.jpg'; // Import your image
import smartTvImage4 from './images/androidiptv4.jpg'; // Import your image

const Android = () => {
  const [isCollapsed, setIsCollapsed] = useState(true); // Initially closed

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  const renderIcon = () => {
    return isCollapsed ? <DownOutlined /> : <UpOutlined />;
  };

  return (
    <div className="toggle-collapse">
      <button className="toggle-collapse-btn" onClick={toggleCollapse}>
        Android {renderIcon()}
      </button>
      {!isCollapsed && (
        <div className="collapse-content">
          <p>Installing IPTV App from Google Play Store<br /><br /><br />
1. Access Google Play Store<br /><br />

    Unlock your Android device and open the Google Play Store app from the home screen or the app drawer.<br /><br />

2. Search for IPTV App<br /><br />

    Tap on the search bar at the top of the Google Play Store.<br />
    Type "IPTV" in the search field and press Enter or Search.<br /><br />

3. Select and Install an IPTV App<br /><br />

    Browse through the search results to find an IPTV app that suits your preferences. Here are some popular options:<br />
       <ul><br />
       <img src={smartTvImage} alt="Smart TV" style={{ maxWidth: '100%', marginBottom: '10px' }} /><br />
       <li>
        <a href="https://play.google.com/store/apps/details?id=com.smarterspro.smartersprotv&hl=en_CA" target="_blank" rel="noopener noreferrer">
          IPTV Smarters Pro
        </a>
      </li>
      <br />
      <li>
        <a href="https://play.google.com/store/apps/details?id=ar.tvplayer.tv&hl=en_CA" target="_blank" rel="noopener noreferrer">
          TiviMate IPTV Player
        </a>
      </li>
      <br />
      <li>
        <a href="https://play.google.com/store/apps/details?id=com.perfectiptv.proplayer&hl=en_CA" target="_blank" rel="noopener noreferrer">
          Perfect Player IPTV
        </a>
      </li>
      <br />
      <li>
        <a href="https://play.google.com/store/apps/details?id=com.gse.smartplayeriptv&hl=en_CA" target="_blank" rel="noopener noreferrer">
          GSE SMART IPTV
        </a>
      </li>
      <br />
      <li>
        <a href="https://play.google.com/store/apps/details?id=com.divergentftb.xtreamplayeranddownloader&hl=en_CA" target="_blank" rel="noopener noreferrer">
          9Xtream (Our Favorite)
        </a>
      </li><br /></ul>
    Tap on the desired IPTV app from the search results to open its app page.<br />
    Tap on "Install" to download and install the app on your Android device.<br />
    Wait for the installation to complete. Depending on your internet connection, this may take a few moments.<br /><br />

4. Launch the Installed IPTV App<br />
<img src={smartTvImage2} alt="Smart TV" style={{ maxWidth: '100%', marginBottom: '10px' }} /><br /><br />

    Once the installation is complete, tap on "Open" from the app page in the Google Play Store.<br />
    Alternatively, you can find the app icon on your home screen or app drawer and tap on it to launch the IPTV app.<br /><br />

5. Configure the IPTV App<br />
<img src={smartTvImage3} alt="Smart TV" style={{ maxWidth: '100%', marginBottom: '10px' }} /><br /><br />

    Follow the on-screen instructions provided by the IPTV app to set up your account or configure the app. This typically involves entering:<br />
        Server URL: Provided by your IPTV service provider.<br />
        Username and Password: Credentials provided by your IPTV service.<br />
        EPG URL <br />(Optional): Electronic Program Guide URL for TV scheduling information.<br /><br />

6. Add IPTV Channels<br /><br />

    After configuring the app, you need to add IPTV channels to start watching content:<br />
        Navigate to the playlist or channel list section within the app.<br />
        Add channels manually by entering the channel name and URL, if you have a playlist file or URL.<br />
        Import playlist: Some apps allow you to import IPTV playlists directly from a file or URL provided by your IPTV service provider.<br /><br />

7. Enjoy IPTV Content<br />
<img src={smartTvImage4} alt="Smart TV" style={{ maxWidth: '100%', marginBottom: '10px' }} /><br /><br />

    Once you have added channels and configured the app, you can start enjoying IPTV content on your Android device:<br />
        Browse through the channel list: Use the apps interface to navigate through the available channels.<br />
        Select a channel: Tap on a channel to start streaming its content. The app will buffer and play the selected channels live stream.<br /><br />

8. Additional Tips<br /><br />

    Internet Connection: Ensure you have a stable internet connection, preferably high-speed broadband, to avoid buffering issues during streaming.<br />
    VPN Usage: Consider using a VPN (Virtual Private Network) when streaming IPTV content for privacy and security reasons, especially if accessing geo-restricted content.<br /><br />

By following these steps, you should be able to install an IPTV app from the Google Play Store on your Android device and start enjoying streaming TV channels over the internet. Adjust settings and preferences within the app to tailor your IPTV experience according to your preferences.</p>
        </div>
      )}
    </div>
  );
};

export default Android;