import React, { useState } from 'react';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import smartTvImage from './images/androidiptv1.jpg'; // Import your image
import smartTvImage2 from './images/androidiptv2.jpg'; // Import your image
import smartTvImage3 from './images/androidiptv3.jpg'; // Import your image
import smartTvImage4 from './images/androidiptv4.jpg'; // Import your image

const SmartTV = () => {
  const [isCollapsed, setIsCollapsed] = useState(true); // Initially closed

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  const renderIcon = () => {
    return isCollapsed ? <DownOutlined /> : <UpOutlined />;
  };

  return (
    <div className="toggle-collapse">
      <button className="toggle-collapse-btn" onClick={toggleCollapse}>
        Smart TV {renderIcon()}
      </button>
      {!isCollapsed && (
        <div className="collapse-content">
          <p>
            How to Install IPTV on a Smart TV (Using Google Play Store)
            <br /><br />
            Smart TVs are equipped with built-in app stores where you can download and install various applications, including IPTV players. Here’s how you can install an IPTV player on your Smart TV through the Google Play Store:
            <br /><br />
            1. Navigate to the App Store
            <br /><br />
            Turn on your Smart TV and ensure it is connected to the internet.
            <br />
            Using your TV remote, navigate to the app store. This is typically labeled as "Google Play Store" or something similar.
            <br /><br />
            2. Search for an IPTV Player
            <br /><br />
            Open the Google Play Store on your Smart TV.
            <br />
            Use the search function to look for an IPTV player application. Here are some popular IPTV players available on the Google Play Store:
            <ul><br />
       <img src={smartTvImage} alt="Smart TV" style={{ maxWidth: '100%', marginBottom: '10px' }} /><br />
       <li>
        <a href="https://play.google.com/store/apps/details?id=com.smarterspro.smartersprotv&hl=en_CA" target="_blank" rel="noopener noreferrer">
          IPTV Smarters Pro
        </a>
      </li>
      <br />
      <li>
        <a href="https://play.google.com/store/apps/details?id=ar.tvplayer.tv&hl=en_CA" target="_blank" rel="noopener noreferrer">
          TiviMate IPTV Player
        </a>
      </li>
      <br />
      <li>
        <a href="https://play.google.com/store/apps/details?id=com.perfectiptv.proplayer&hl=en_CA" target="_blank" rel="noopener noreferrer">
          Perfect Player IPTV
        </a>
      </li>
      <br />
      <li>
        <a href="https://play.google.com/store/apps/details?id=com.gse.smartplayeriptv&hl=en_CA" target="_blank" rel="noopener noreferrer">
          GSE SMART IPTV
        </a>
      </li>
      <br />
      <li>
        <a href="https://play.google.com/store/apps/details?id=com.divergentftb.xtreamplayeranddownloader&hl=en_CA" target="_blank" rel="noopener noreferrer">
          9Xtream (Our Favorite)
        </a>
      </li><br /></ul>
            3. Install the IPTV Player
            <br /><br />
            Once you find the desired IPTV player app, select it to view more details.
            <br />
            Click on the "Install" button to download and install the app on your Smart TV.
            <br /><br />
            4. Launch the IPTV Player
            <br /><br />
            After installation is complete, locate the app in your Smart TV's app menu.
            <br />
            Launch the IPTV player app by selecting it with your remote.
            <br /><br />
            5. Set Up IPTV Service
            <br /><br />
            Depending on the IPTV player app you chose, follow the on-screen instructions to set up your IPTV service.<br />
            <img src={smartTvImage2} alt="Smart TV" style={{ maxWidth: '100%', marginBottom: '10px' }} /><br />
            Typically, you will need to enter your IPTV provider's credentials or upload a M3U playlist URL to start streaming IPTV channels.<br />
            <img src={smartTvImage3} alt="Smart TV" style={{ maxWidth: '100%', marginBottom: '10px' }} /><br />
            <br /><br />
            6. Enjoy IPTV Channels
            <br /><br />
            Once set up, you can browse and watch IPTV channels directly on your Smart TV using the installed IPTV player app.
            <br />
            <img src={smartTvImage4} alt="Smart TV" style={{ maxWidth: '100%', marginBottom: '10px' }} /><br /><br />
            Notes:
            <br /><br />
            Compatibility: Ensure that the IPTV player app you choose is compatible with your Smart TV model and operating system version.
            <br />
            Internet Connection: A stable internet connection is required to stream IPTV channels smoothly.
            <br />
            Updates: Periodically check for updates for your IPTV player app to ensure you have the latest features and security patches.
            <br /><br />
            By following these steps, you can easily install and set up an IPTV player on your Smart TV using the Google Play Store. Enjoy streaming your favorite IPTV channels directly on your television!
          </p>
        </div>
      )}
    </div>
  );
};

export default SmartTV;