import React, { useState } from 'react';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import smartTvImage from './images/androidiptv1.jpg'; // Import your image
import smartTvImage2 from './images/androidiptv2.jpg'; // Import your image
import smartTvImage3 from './images/androidiptv3.jpg'; // Import your image
import smartTvImage4 from './images/androidiptv4.jpg'; // Import your image

const Iphone = () => {
  const [isCollapsed, setIsCollapsed] = useState(true); // Initially closed

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  const renderIcon = () => {
    return isCollapsed ? <DownOutlined /> : <UpOutlined />;
  };

  return (
    <div className="toggle-collapse">
      <button className="toggle-collapse-btn" onClick={toggleCollapse}>
        Iphone {renderIcon()}
      </button>
      {!isCollapsed && (
        <div className="collapse-content">
          <p>Installing IPTV App from App Store<br /><br />
1. Access the App Store<br /><br />

    Unlock your iPhone or iPad and open the App Store from the home screen.<br /><br />

2. Search for IPTV App<br /><br />

    Tap on the search icon (magnifying glass) located at the bottom of the screen.<br />
    Type "IPTV" in the search bar and tap on Search.<br /><br />

3. Select and Install an IPTV App<br />
<img src={smartTvImage} alt="Smart TV" style={{ maxWidth: '100%', marginBottom: '10px' }} /><br /><br /><br />

    Browse through the search results to find an IPTV app that suits your preferences. Here are some popular options available on the App Store:<br />
    <ul>
    <li>
        <a href="https://apps.apple.com/us/app/iptv-smarter-player/id6450916775">
          IPTV Smarter Player
        </a>
      </li>
      <br />
      <li>
        <a href="https://apps.apple.com/us/app/smarters-player-lite/id1628995509" target="_blank" rel="noopener noreferrer">
          IPTV Smarters Player Lite
        </a>
      </li>
      <br />
      <li>
        <a href="https://apps.apple.com/us/app/iptv-smart-player/id6448987395" target="_blank" rel="noopener noreferrer">
          IPTV Smart Player
        </a>
      </li>
      <br />
      <li>
        <a href="https://apps.apple.com/us/app/iptv-smarters-xtream-iptv/id1573898785" target="_blank" rel="noopener noreferrer">
          IPTV Smarters - Xtream IPTV
        </a>
      </li>
      <br />
      <li>
        <a href="https://apps.apple.com/us/app/iptvx/id1451470024">
          IPTVX (App Subscription Required)
        </a>
      </li><br />
</ul>

    Tap on the desired IPTV app from the search results to open its app page.<br /><br />

    Tap on "Get" or the download button (cloud icon with arrow) to start downloading and installing the app on your iPhone or iPad.<br /><br />

    Authenticate with Face ID, Touch ID, or your Apple ID password if prompted.<br /><br />

4. Launch the Installed IPTV App<br />
<img src={smartTvImage2} alt="Smart TV" style={{ maxWidth: '100%', marginBottom: '10px' }} /><br /><br /><br />

    Once the installation is complete, tap on "Open" from the app page in the App Store.<br />
    Alternatively, you can find the app icon on your home screen or in the App Library (iOS 14 and later) and tap on it to launch the IPTV app.<br /><br />

5. Configure the IPTV App<br />
<img src={smartTvImage3} alt="Smart TV" style={{ maxWidth: '100%', marginBottom: '10px' }} /><br /><br /><br />

    Follow the on-screen instructions provided by the IPTV app to set up your account or configure the app. This typically involves entering:<br />
        Server URL: Provided by your IPTV service provider.<br />
        Username and Password: Credentials provided by your IPTV service.<br />
        EPG URL (Optional): Electronic Program Guide URL for TV scheduling information.<br /><br />

6. Add IPTV Channels<br /><br />

    After configuring the app, you need to add IPTV channels to start watching content:<br />
        Navigate to the playlist or channel list section within the app.<br />
        Add channels manually by entering the channel name and URL, if you have a playlist file or URL.<br />
        Import playlist: Some apps allow you to import IPTV playlists directly from a file or URL provided by your IPTV service provider.<br /><br />

7. Enjoy IPTV Content<br />
<img src={smartTvImage4} alt="Smart TV" style={{ maxWidth: '100%', marginBottom: '10px' }} /><br /><br /><br />

    Once you have added channels and configured the app, you can start enjoying IPTV content on your iPhone or iPad:<br />
        Browse through the channel list: Use the app’s interface to navigate through the available channels.<br />
        Select a channel: Tap on a channel to start streaming its content. The app will buffer and play the selected channel’s live stream.<br /><br />

8. Additional Tips<br /><br />

    Internet Connection: Ensure you have a stable internet connection, preferably high-speed Wi-Fi or cellular data, to avoid buffering issues during streaming.<br />
    VPN Usage: Consider using a VPN (Virtual Private Network) when streaming IPTV content for privacy and security reasons, especially if accessing geo-restricted content.</p>
        </div>
      )}
    </div>
  );
};

export default Iphone;