import * as Route from '@/constants/routes';
import logo from '@/images/logo-full.png';
import React from 'react';
import { useLocation } from 'react-router-dom';

const Footer = () => {
  const { pathname } = useLocation();

  const visibleOnlyPath = [
    Route.HOME,
    Route.SHOP
  ];

  return !visibleOnlyPath.includes(pathname) ? null : (
    <footer className="footer">
      <div className="footer-col-1">
        <strong>
          <span>
            Developed by
            {' '}
            <a href="https://ultimateappsca.info">UltimateAppsCA</a>
          </span>
        </strong>
      </div>
      <div className="footer-col-2">
        <img alt="Footer logo" className="footer-logo" src={logo} />
        <h5>
          &copy;&nbsp;
          {new Date().getFullYear()}
        </h5>
      </div>
      <div className="footer-col-3">
      <div className="hours-of-operation">
          <h5>Hours of Operation</h5>
          <p>Monday - Sunday: 8 AM - 10 PM EST</p>
        </div>
        <strong>
          <span>
            Contact Us &nbsp;
            <a href="mailto:support@dream-tv.cc?subject=Website%20Inquiry&body=Dear%20DreamTV,">HERE</a>
          </span>
        </strong><br />
        <strong>
          <span>
            <a href="sms:+17054172521?body=Hello%20DreamTV%20Support">Text Support</a>
          </span>
        </strong>
      </div>
    </footer>
  );
};

export default Footer;